@import 'partials/variables';
@import 'partials/mixins';

/***Typography***/
@import 'partials/typography';

/***Layout***/
@import 'partials/layout';
/***Forms***/
@import 'partials/forms';

/***Layout***/
@import 'partials/responsive';

@import '../../node_modules/skeleton-css/css/normalize.css';
@import '../../node_modules/skeleton-css/css/skeleton.css';
@import '../../node_modules/slick-carousel/slick/slick.css';



