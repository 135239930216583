/***Typography***/
@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap&subset=latin-ext");
@import url(../../node_modules/skeleton-css/css/normalize.css);
@import url(../../node_modules/skeleton-css/css/skeleton.css);
@import url(../../node_modules/slick-carousel/slick/slick.css);
body {
  font-family: "Ubuntu", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif; }

a {
  transition: all .25s; }
  a:hover {
    color: #b6191b; }

.text--highlight {
  color: #e02224; }

.text--gray {
  color: #b4b4b4; }

.text--bigger {
  font-size: 160%; }

/***Layout***/
.section {
  margin-top: 10rem; }

.subpage .mainFooter:after {
  background-image: url("/images/footer-shape-right-white.svg"); }

.subpage .mainFooter:before {
  background-image: url("/images/footer-shape-white.svg"); }

section.content {
  margin-bottom: 4rem; }
  section.content a {
    color: #e02224;
    text-decoration: none; }
    section.content a:hover {
      color: #e02224; }
  section.content h1 {
    font-weight: 600;
    font-size: 4rem;
    margin-bottom: 4rem;
    padding-top: 2rem;
    text-align: center; }

.mainHeader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  padding: 2% 4%;
  transition: all .5s; }
  .mainHeader.minimize, .mainHeader.subpage {
    background: black;
    position: fixed;
    max-height: 74px;
    padding: 5px 4%; }
    .mainHeader.minimize .mainNav, .mainHeader.subpage .mainNav {
      align-items: center;
      max-height: 74px; }
    .mainHeader.minimize .mainNav__menu, .mainHeader.minimize .mainNav__menu li, .mainHeader.subpage .mainNav__menu, .mainHeader.subpage .mainNav__menu li {
      margin: 0; }
    .mainHeader.minimize .mainNav__logo, .mainHeader.subpage .mainNav__logo {
      margin-top: auto; }
      .mainHeader.minimize .mainNav__logo svg, .mainHeader.subpage .mainNav__logo svg {
        max-width: 80px;
        width: 80px;
        height: 80px; }
  .mainHeader .mainNav {
    display: flex;
    justify-content: space-between; }
    .mainHeader .mainNav__hamburger {
      z-index: 10;
      display: none;
      border: 0;
      margin: 0;
      padding: 0; }
      .mainHeader .mainNav__hamburger span {
        height: 3px;
        width: 4rem;
        background: white;
        display: block;
        margin: 1rem 0;
        transition: all .25s; }
    .mainHeader .mainNav.open .mainNav__hamburger span:nth-of-type(1) {
      transform: rotate(45deg);
      transform-origin: 0.3rem; }
    .mainHeader .mainNav.open .mainNav__hamburger span:nth-of-type(2) {
      transform: translateX(100%);
      opacity: 0; }
    .mainHeader .mainNav.open .mainNav__hamburger span:nth-of-type(3) {
      transform: rotate(-45deg);
      transform-origin: 0rem; }
    .mainHeader .mainNav__menu {
      margin-top: 1%;
      display: flex;
      transition: all .5s;
      justify-content: space-between; }
      .mainHeader .mainNav__menu li {
        padding: 0 10px;
        list-style: none; }
      .mainHeader .mainNav__menu a {
        color: #ffffff;
        text-decoration: none; }
        .mainHeader .mainNav__menu a:hover {
          color: #e02224; }
    .mainHeader .mainNav__logo svg {
      width: 185px;
      max-width: 185px;
      transition: all .5s; }
    .mainHeader .mainNav__ctaBtn {
      margin: 0;
      text-transform: none;
      font-size: 100%;
      font-weight: 400;
      letter-spacing: inherit;
      display: flex;
      align-items: center;
      padding: 15px; }
      .mainHeader .mainNav__ctaBtn svg {
        fill: #ffffff;
        height: 18px;
        width: auto;
        margin-right: 15px; }

.mainSlider {
  position: relative;
  background: black; }
  .mainSlider .slick-dots {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    right: 0;
    width: 50%;
    display: flex;
    justify-content: flex-end; }
    .mainSlider .slick-dots li {
      position: relative;
      list-style: none;
      cursor: pointer; }
      .mainSlider .slick-dots li button {
        margin: 0;
        padding: 0 2rem;
        padding-left: 4rem;
        border: 0;
        color: white;
        font-weight: 600;
        font-size: 1.4rem; }
      .mainSlider .slick-dots li:after {
        transition: all .5s;
        content: "";
        position: absolute;
        width: 100px;
        left: 0;
        top: 0;
        background-repeat: no-repeat;
        height: 100%;
        opacity: 0.1;
        background-image: url("/images/slide-dot.svg");
        z-index: -1; }
      .mainSlider .slick-dots li:hover:after {
        opacity: 0.3; }
      .mainSlider .slick-dots li.slick-active:after {
        background-image: url("/images/slide-dot-active.svg");
        opacity: 1; }
  .mainSlider__slider__item {
    position: relative;
    height: 90vh;
    background: black; }
    .mainSlider__slider__item__image {
      opacity: 0.2;
      z-index: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      background-size: cover; }
    .mainSlider__slider__item__text {
      position: relative;
      z-index: 2;
      color: #fff;
      height: 90vh;
      width: 40%;
      margin-left: 20%;
      display: flex;
      align-items: center; }
      .mainSlider__slider__item__text h2 {
        font-size: 7rem;
        font-weight: 500;
        line-height: 8rem; }
  .mainSlider__aside {
    position: absolute;
    max-width: 300px;
    width: 20%;
    padding-left: 30%;
    height: 100%;
    z-index: 10;
    top: 1px;
    right: 0;
    background-image: url(/images/slide-shape.svg);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 100%; }
    .mainSlider__aside__company {
      position: absolute;
      bottom: 5%; }
      .mainSlider__aside__company a {
        text-decoration: none;
        color: #b4b4b4; }
        .mainSlider__aside__company a:hover {
          color: #e02224; }
    .mainSlider__aside__contact {
      position: absolute;
      top: 20%;
      color: white; }
      .mainSlider__aside__contact .text--hightlight {
        color: #e02224; }
      .mainSlider__aside__contact a {
        color: white;
        text-decoration: none; }
        .mainSlider__aside__contact a:hover {
          color: #e02224; }

.contactForm {
  background: black;
  color: white;
  min-height: 500px;
  position: relative; }
  .contactForm .proceeding .contactForm__loader {
    display: flex; }
  .contactForm__loader {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 2;
    align-items: center;
    justify-content: center; }
  .contactForm a {
    text-decoration: none;
    color: white; }
    .contactForm a:hover {
      color: #e02224; }
  .contactForm button.button {
    padding: 0% 7%;
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 400; }
  .contactForm form {
    margin: 10rem 0;
    position: relative; }
  .contactForm:before {
    left: 0;
    top: 0;
    content: "";
    position: absolute;
    height: 13vw;
    width: 13vw;
    background-image: url("/images/form-shape.svg");
    background-size: cover; }
  .contactForm:after {
    right: 0;
    top: 0;
    content: "";
    position: absolute;
    height: 13vw;
    width: 13vw;
    background-image: url("/images/form-shape-right.svg");
    background-size: cover; }

.slide-text-animation {
  transition: all 1s;
  opacity: 1;
  transform: translateY(0px); }
  .slide-text-animation-out {
    opacity: 0;
    transform: translateY(50px); }

.process {
  text-align: center; }
  .process .step {
    text-transform: uppercase;
    font-size: 2rem; }
  .process__stepNo {
    font-size: 9rem;
    line-height: 9rem;
    font-weight: 600;
    color: #e02224;
    display: block; }
  .process h3 {
    font-size: 3rem;
    margin-top: 1.5rem;
    margin-bottom: 0;
    min-height: 7rem; }
  .process img {
    height: 105px;
    width: auto;
    margin: 4rem 0; }
  .process p {
    padding: 0 4em; }

.about {
  background: #e02224;
  padding: 10rem 0;
  color: white; }
  .about h2 {
    font-weight: 600;
    font-size: 5rem; }
  .about__ctaBtn {
    text-transform: none;
    font-size: 100%;
    font-weight: 400;
    letter-spacing: inherit;
    display: flex;
    align-items: center;
    padding: 15px;
    color: #e02224;
    background: white;
    border-color: white;
    width: 158px; }
    .about__ctaBtn:hover, .about__ctaBtn:active, .about__ctaBtn:visited, .about__ctaBtn:focus {
      border-color: white;
      color: #e02224;
      opacity: 0.8; }
    .about__ctaBtn svg {
      fill: #e02224;
      height: 18px;
      width: auto;
      margin-right: 15px; }

.secure {
  text-align: center; }
  .secure h2 {
    font-weight: 600;
    font-size: 5rem;
    margin-bottom: 8rem; }
  .secure svg {
    width: 50px;
    height: auto; }

.contactForm {
  background: black;
  color: white;
  position: relative;
  text-align: center;
  padding-top: 15rem; }
  .contactForm h2 {
    font-weight: 600;
    font-size: 4rem;
    margin-bottom: 4rem; }
  .contactForm:before {
    left: 0;
    top: 0;
    content: "";
    position: absolute;
    height: 13vw;
    width: 13vw;
    background-image: url("/images/form-shape.svg");
    background-size: cover; }
  .contactForm:after {
    right: 0;
    top: 0;
    content: "";
    position: absolute;
    height: 13vw;
    width: 13vw;
    background-image: url("/images/form-shape-right.svg");
    background-size: cover; }

.mainFooter {
  background-color: #e02224;
  position: relative;
  color: white;
  padding-top: 7rem;
  padding-bottom: 2rem; }
  .mainFooter a {
    color: white;
    text-decoration: none; }
    .mainFooter a:hover {
      color: #b6191b; }
  .mainFooter svg {
    width: 130px; }
  .mainFooter:before {
    left: 0;
    top: 0;
    content: "";
    position: absolute;
    height: 7vw;
    width: 7vw;
    background-image: url("/images/footer-shape.svg");
    background-size: cover; }
  .mainFooter:after {
    right: 0;
    top: 0;
    content: "";
    position: absolute;
    height: 7vw;
    width: 7vw;
    background-image: url("/images/footer-shape-right.svg");
    background-size: cover; }

[data-animate] {
  transition: all 1s;
  opacity: 0;
  transform: translateY(10%) scale(0.9); }
  [data-animate]:nth-of-type(1) {
    transition-delay: 0s; }
  [data-animate]:nth-of-type(2) {
    transition-delay: 0.25s; }
  [data-animate]:nth-of-type(2) {
    transition-delay: 0.5s; }
  [data-animate]:nth-of-type(3) {
    transition-delay: 0.75s; }
  [data-animate]:nth-of-type(4) {
    transition-delay: 1s; }
  [data-animate]:nth-of-type(5) {
    transition-delay: 1.25s; }
  [data-animate].animated {
    opacity: 1;
    transform: translateY(0%) scale(1); }

.alert {
  padding: 1% 5%;
  fill: white;
  align-items: center;
  justify-content: center;
  transition: all .5s;
  margin-top: 5%;
  display: none; }
  .alert svg {
    margin: 0 5%; }
  .alert.show {
    display: flex; }
  .alert.success {
    background: #8ed3af; }
  .alert.error {
    background: #e02224; }

.svg-defs {
  display: none; }

.grecaptcha-badge {
  z-index: 99999; }

/***Forms***/
.button {
  border-radius: 0px;
  transition: all .25s;
  text-transform: inherit;
  font-size: 1.3rem; }
  .button .button-primary {
    background: #e02224; }

.button.button-primary, button.button-primary, input[type="button"].button-primary, input[type="reset"].button-primary, input[type="submit"].button-primary {
  background: #e02224;
  border-color: #e02224;
  color: white; }
  .button.button-primary:hover, .button.button-primary:active, .button.button-primary:focus, button.button-primary:hover, button.button-primary:active, button.button-primary:focus, input[type="button"].button-primary:hover, input[type="button"].button-primary:active, input[type="button"].button-primary:focus, input[type="reset"].button-primary:hover, input[type="reset"].button-primary:active, input[type="reset"].button-primary:focus, input[type="submit"].button-primary:hover, input[type="submit"].button-primary:active, input[type="submit"].button-primary:focus {
    background: #b6191b;
    border-color: #b6191b; }

input[type="email"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="url"]:focus, select:focus, textarea:focus {
  border: 0; }

fieldset {
  margin: 0 0 3rem;
  padding: 0;
  border: none; }

.form-radio,
.form-group {
  position: relative;
  margin-top: 2.25rem;
  margin-bottom: 2.25rem; }

.form-inline > .form-group,
.form-inline > .btn {
  display: inline-block;
  margin-bottom: 0; }

.form-help {
  margin-top: 0.125rem;
  margin-left: 0.125rem;
  color: #b3b3b3;
  font-size: 1rem;
  left: 0;
  display: none; }

.checkbox .form-help, .form-radio .form-help, .form-group .form-help {
  position: absolute;
  width: 100%; }

.checkbox .form-help {
  position: relative;
  margin-bottom: 1rem; }

.form-radio .form-help {
  padding-top: 0.25rem;
  margin-top: -1rem; }

.form-group input {
  height: 2.6rem; }

.form-group textarea {
  resize: none; }

.form-group select {
  width: 100%;
  font-size: 1.5rem;
  height: 2.6rem;
  padding: 0.125rem 0.125rem 0.0625rem;
  background: none;
  border: none;
  line-height: 1.6;
  box-shadow: none; }

.form-group .control-label {
  position: absolute;
  top: 0.25rem;
  pointer-events: none;
  padding-left: 0.125rem;
  z-index: 1;
  color: white;
  font-size: 1.5rem;
  font-weight: normal;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease; }

.form-group .bar {
  position: relative;
  border-bottom: 1px solid #424345;
  display: block; }

.form-group .bar::before {
  content: '';
  height: 0.125rem;
  width: 0;
  left: 50%;
  bottom: -0.0625rem;
  position: absolute;
  background: #e02224;
  -webkit-transition: left 0.28s ease, width 0.28s ease;
  transition: left 0.28s ease, width 0.28s ease;
  z-index: 2; }

.form-group input,
.form-group textarea {
  display: block;
  background: none;
  padding: 0.125rem 0.125rem 0.0625rem;
  font-size: 1.5rem;
  border-width: 0;
  border-color: transparent;
  line-height: 2.5;
  width: 100%;
  color: transparent;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
  box-shadow: none; }

.form-group textarea {
  line-height: 1.3; }

.form-group input[type="file"] {
  line-height: 1; }

.form-group input[type="file"] ~ .bar {
  display: none; }

.form-group select,
.form-group input:focus,
.form-group input.has-value,
.form-group input.form-file,
.form-group input.has-value,
.form-group textarea:focus,
.form-group textarea.has-value,
.form-group textarea.form-file,
.form-group textarea.has-value {
  color: #fff; }

.form-group select ~ .control-label,
.form-group input:focus ~ .control-label,
.form-group input.has-value ~ .control-label,
.form-group input.form-file ~ .control-label,
.form-group input.has-value ~ .control-label,
.form-group textarea:focus ~ .control-label,
.form-group textarea.has-value ~ .control-label,
.form-group textarea.form-file ~ .control-label,
.form-group textarea.has-value ~ .control-label {
  font-size: 1.1rem;
  color: gray;
  top: -1.5rem;
  left: 0; }

.form-group select:focus,
.form-group input:focus,
.form-group textarea:focus {
  outline: none; }

.form-group select:focus ~ .control-label,
.form-group input:focus ~ .control-label,
.form-group textarea:focus ~ .control-label {
  color: #e02224; }

.form-group select:focus ~ .bar::before,
.form-group input:focus ~ .bar::before,
.form-group textarea:focus ~ .bar::before {
  width: 100%;
  left: 0; }

.checkbox label,
.form-radio label {
  position: relative;
  cursor: pointer;
  padding-left: 2rem;
  text-align: left;
  color: #424345;
  font-weight: 400;
  display: block;
  font-size: 1.1rem;
  line-height: 1.2rem; }
  .checkbox label a,
  .form-radio label a {
    color: #818286; }

.checkbox input,
.form-radio input {
  width: auto;
  opacity: 0.00000001;
  position: absolute;
  left: 0; }

.radio {
  margin-bottom: 1rem; }

.radio .helper {
  position: absolute;
  top: -0.25rem;
  left: -0.25rem;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #999; }

.radio .helper::before, .radio .helper::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 0.25rem;
  width: 1rem;
  height: 1rem;
  -webkit-transition: -webkit-transform 0.28s ease;
  transition: -webkit-transform 0.28s ease;
  transition: transform 0.28s ease;
  transition: transform 0.28s ease, -webkit-transform 0.28s ease;
  border-radius: 50%;
  border: 0.125rem solid currentColor; }

.radio .helper::after {
  -webkit-transform: scale(0);
  transform: scale(0);
  background-color: #e02224;
  border-color: #e02224; }

.radio label:hover .helper {
  color: #e02224; }

.radio input:checked ~ .helper::after {
  -webkit-transform: scale(0.5);
  transform: scale(0.5); }

.radio input:checked ~ .helper::before {
  color: #e02224; }

.checkbox {
  margin-top: 3rem;
  margin-bottom: 1rem; }

.checkbox .helper {
  color: #999;
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  z-index: 0;
  border: 0.125rem solid currentColor;
  border-radius: 0.0625rem;
  -webkit-transition: border-color 0.28s ease;
  transition: border-color 0.28s ease; }

.checkbox .helper::before, .checkbox .helper::after {
  position: absolute;
  height: 0;
  width: 0.2rem;
  background-color: #e02224;
  display: block;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  border-radius: 0.25rem;
  content: '';
  -webkit-transition: opacity 0.28s ease, height 0s linear 0.28s;
  transition: opacity 0.28s ease, height 0s linear 0.28s;
  opacity: 0; }

.checkbox .helper::before {
  top: 0.65rem;
  left: 0.38rem;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  box-shadow: 0 0 0 0.062rem #e02224; }

.checkbox .helper::after {
  top: 0.3rem;
  left: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.checkbox label:hover .helper {
  color: #e02224; }

.checkbox input:checked ~ .helper {
  color: #e02224; }

.checkbox input:checked ~ .helper::after, .checkbox input:checked ~ .helper::before {
  opacity: 1;
  -webkit-transition: height 0.28s ease;
  transition: height 0.28s ease; }

.checkbox input:checked ~ .helper::after {
  height: 0.5rem; }

.checkbox input:checked ~ .helper::before {
  height: 1.2rem;
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s; }

.radio + .radio,
.checkbox + .checkbox {
  margin-top: 1rem; }

.has-error .legend.legend, .has-error.form-group .control-label.control-label {
  color: #d9534f; }

.has-error .form-help {
  color: #d9534f; }

.has-error .helper {
  border-color: #d9534f; }

.has-error.form-group .form-help,
.has-error.form-group .helper, .has-error.checkbox .form-help,
.has-error.checkbox .helper, .has-error.radio .form-help,
.has-error.radio .helper, .has-error.form-radio .form-help,
.has-error.form-radio .helper {
  color: #d9534f; }

.has-error .bar::before {
  background: #d9534f;
  left: 0;
  width: 100%; }

.has-error .form-help {
  display: block; }

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px; }

.lds-spinner div {
  transform-origin: 32px 32px;
  animation: lds-spinner 1.2s linear infinite; }

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 29px;
  width: 5px;
  height: 14px;
  border-radius: 20%;
  background: #fff; }

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s; }

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s; }

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s; }

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s; }

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s; }

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s; }

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s; }

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s; }

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s; }

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s; }

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s; }

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s; }

@keyframes lds-spinner {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/***Layout***/
.container {
  max-width: 100%; }
  @media (min-width: 1500px) {
    .container {
      width: 1440px; } }

@media (max-width: 1300px) {
  .process p {
    padding: 0 2rem; } }

@media (max-width: 1100px) {
  .mainHeader.minimize .mainNav__menu, .mainHeader.minimize .mainNav__menu li {
    margin: 1.5rem 0; }
  .mainHeader.minimize .mainNav__logo svg {
    max-width: 80px;
    width: 80px; }
  .mainHeader .mainNav {
    display: flex;
    align-items: center; }
    .mainHeader .mainNav__ctaBtn {
      font-size: 1.2rem;
      padding: 2%; }
    .mainHeader .mainNav__menu {
      display: flex;
      align-items: center;
      margin: 0; }
      .mainHeader .mainNav__menu li {
        font-size: 1.2rem; }
    .mainHeader .mainNav__logo svg {
      max-width: 90px; }
  .mainSlider__slider__item__text h2 {
    font-size: 6rem;
    line-height: 6rem; }
  .mainSlider__aside__company {
    right: auto;
    bottom: 0; }
  .process p {
    padding: 0; }
  .process h3 {
    font-size: 2.5rem; }
  .about h2, .secure h2 {
    font-size: 4rem; }
  .text--bigger {
    font-size: 140%; } }

@media (max-width: 850px) {
  .mainSlider {
    min-height: 350px; }
    .mainSlider__slider__item {
      min-height: 350px; }
      .mainSlider__slider__item__text {
        min-height: 350px; }
  .mainFooter {
    font-size: 1.3rem; }
  .mainHeader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 2% 4%;
    transition: all .5s; }
    .mainHeader.minimize .mainNav__menu, .mainHeader.minimize .mainNav__menu li {
      margin: 1.5rem 0; }
    .mainHeader.minimize .mainNav__logo svg {
      max-width: 80px;
      width: 80px; }
    .mainHeader .mainNav {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .mainHeader .mainNav.open .mainNav__menu {
        transform: translateX(0);
        background: black; }
        .mainHeader .mainNav.open .mainNav__menu li {
          opacity: 1;
          transform: translateX(0%); }
      .mainHeader .mainNav__hamburger {
        display: block; }
      .mainHeader .mainNav__menu {
        transform: translateX(-100%);
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        transition: all .5s;
        background: transparent;
        align-items: center;
        justify-content: center; }
        .mainHeader .mainNav__menu li {
          opacity: 0;
          transform: translateY(-100%);
          transition: all .5s;
          margin: 1.5rem 0; }
          .mainHeader .mainNav__menu li:nth-of-type(1) {
            transition-delay: 0.5s; }
          .mainHeader .mainNav__menu li:nth-of-type(2) {
            transition-delay: 0.75s; }
          .mainHeader .mainNav__menu li:nth-of-type(3) {
            transition-delay: 1s; }
          .mainHeader .mainNav__menu li:nth-of-type(4) {
            transition-delay: 1.25s; }
          .mainHeader .mainNav__menu li a {
            padding: 2rem 0; }
      .mainHeader .mainNav__logo {
        z-index: 10; }
        .mainHeader .mainNav__logo svg {
          width: 90px;
          max-width: 90px;
          transition: all .5s; }
  .process .step {
    display: block;
    margin-top: 3rem; }
  .process__stepNo {
    font-size: 7rem; }
  .process h3 {
    min-height: auto;
    font-size: 2.2rem;
    margin-top: 1rem; }
  .process p {
    padding: 0em; }
  .process img {
    margin: 2rem 0; }
  .section {
    margin-top: 6rem; }
  .text--bigger {
    font-size: 110%; }
  .about {
    padding: 5rem 0; }
  .secure h2 {
    margin-bottom: 4rem; }
  section.content h1 {
    padding-top: 5rem; }
  .about h2, .secure h2, section.content h1 {
    font-size: 3.5rem; }
  .contactForm {
    padding-top: 10rem; }
    .contactForm form {
      margin: 5rem 0; }
    .contactForm h2 {
      font-size: 3rem; }
    .contactForm .button.button-primary {
      width: 100%;
      padding: 0%;
      justify-content: center;
      margin: 3rem 0; }
  .mainFooter {
    text-align: center; }
  .mainSlider__slider__item {
    position: relative;
    height: 60vh;
    background: black; }
    .mainSlider__slider__item__text {
      position: relative;
      z-index: 2;
      color: #fff;
      height: 60vh;
      width: 40%;
      margin-left: 20%;
      display: flex;
      align-items: center; }
      .mainSlider__slider__item__text h2 {
        font-size: 4rem;
        line-height: 4rem; }
  .mainSlider__aside__company {
    display: none; }
  .mainSlider__aside__contact {
    display: none; }
  .form-group .control-label {
    font-size: 1.25rem; } }

@media (max-width: 500px) {
  .mainHeader .mainNav__ctaBtn {
    font-size: 1.2rem;
    padding: 2%; }
  .mainSlider__slider__item {
    position: relative;
    height: 60vh;
    background: black; }
    .mainSlider__slider__item__text {
      width: 100%;
      margin: 0 5%; }
      .mainSlider__slider__item__text h2 {
        font-size: 3.5rem; }
  .about h2, .secure h2 {
    font-size: 3.5rem; } }
