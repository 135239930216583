@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap&subset=latin-ext');

body {
  font-family: "Ubuntu", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

a {
  transition: all .25s;
  &:hover {
    color: darken($base-color,10);
  }
}

.text {
  &--highlight {
    color: $base-color;
  }
  &--gray {
    color: $light-gray;
  }
  &--bigger {
    font-size: 160%;
  }
}