
.container {
  max-width:100%;
  @media (min-width: 400px) {

  }

  @media (min-width: 550px) {

  }

  @media (min-width: 991px) {

  }


  @media (min-width: 1200px) {

  }

  @media (min-width: 1500px) {
    width:1440px;
  }


}

@media (max-width:1300px) {
  .process p {
    padding: 0 2rem;
  }
}

@media (max-width:1100px) {
  .mainHeader {

    &.minimize {
      .mainNav__menu, .mainNav__menu li {margin: 1.5rem 0;}
      .mainNav__logo {
        svg {max-width:80px;width:80px;}
      }
    }
    .mainNav {
      display:flex;
      align-items:center;
      &__ctaBtn {
        font-size:1.2rem;
        padding:2%;
      }
      &__menu {
        display:flex;
        align-items:center;
        margin:0;
        li {
          font-size:1.2rem;
        }
      }
      &__logo {
        svg {
          max-width:90px;
        }
      }
    }
  }

  .mainSlider {
    &__slider {

      &__item {


        &__text {

          h2 {
            font-size: 6rem;
            line-height: 6rem;
          }
        }
      }
    }

    &__aside {
      &__company {
        right:auto;
        bottom:0;
      }

      &__contact {
      }
    }

  }

  .process {
    p {
      padding:0;
    }
    h3 {
      font-size:2.5rem;
    }

  }

  .about h2, .secure h2 {
    font-size: 4rem;
  }

  .text {
    &--bigger {
      font-size:140%;
    }
  }
}

@media (max-width: 850px) {

  .mainSlider {
    min-height:350px;
    &__slider {
      &__item {
        min-height:350px;
        &__text {
          min-height:350px;
        }
      }
    }
  }

  .mainFooter {
    font-size:1.3rem;
  }
  .mainHeader {
    position:absolute;
    top:0;
    left:0;
    right:0;
    z-index:99;
    padding: 2% 4%;
    transition: all .5s;
    &.minimize {
      .mainNav__menu, .mainNav__menu li {margin: 1.5rem 0;}
      .mainNav__logo {
        svg {max-width:80px;width:80px;}
      }
    }
    .mainNav {
      display:flex;
      justify-content: space-between;
      align-items:center;

      &.open {
        .mainNav {
          &__menu {
            transform:translateX(0);
            background:black;
            li {
              opacity: 1;
              transform: translateX(0%);
            }
          }
        }
      }

      &__hamburger {
        display:block;
      }

      &__menu {
        transform:translateX(-100%);
        flex-direction:column;
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100vh;
        transition: all .5s;
        background:transparent;

        align-items:center;
        justify-content: center;
        li {
          opacity:0;
          transform:translateY(-100%);
          transition: all .5s;
          margin: 1.5rem 0;
          &:nth-of-type(1) {transition-delay:0.5s;}
          &:nth-of-type(2) {transition-delay:0.75s;}
          &:nth-of-type(3) {transition-delay:1s;}
          &:nth-of-type(4) {transition-delay:1.25s;}
          a {padding: 2rem 0;}
        }
      }
      &__logo {
        z-index:10;
        svg {width:90px;max-width:90px;transition: all .5s;}
      }
    }
  }

  .process {

    .step {
      display:block;
      margin-top:3rem;
    }

    &__stepNo {
      font-size:7rem;
    }
    h3 {
      min-height:auto;
      font-size:2.2rem;
      margin-top:1rem;
    }
    p {
      padding: 0em;
    }
    img {
      margin: 2rem 0;
    }
  }

  .section {
    margin-top:6rem;
  }
  .text {
    &--bigger {
      font-size: 110%;
    }
  }

  .about {
    padding: 5rem 0;

  }

  .secure h2 {
    margin-bottom:4rem;
  }
  section.content h1 {padding-top: 5rem;}
  .about h2, .secure h2, section.content h1 {
    font-size: 3.5rem;
  }
  .contactForm {
    padding-top:10rem;
    form {
      margin: 5rem 0;
    }
    h2 {font-size:3rem;}
    .button.button-primary {
      width:100%;
      padding: 0%;
      justify-content: center;
      margin: 3rem 0;
    }
  }

  .mainFooter {
    text-align:center;
  }



  .mainSlider {
    &__slider {

      &__item {
        position:relative;
        height:60vh;
        background:black;

        &__text {
          position: relative;
          z-index: 2;
          color: #fff;
          height: 60vh;
          width: 40%;
          margin-left: 20%;
          display: flex;
          align-items: center;
          h2 {
            font-size: 4rem;
            line-height: 4rem;
          }
        }
      }
    }

    &__aside {
      &__company {
        display:none;
      }

      &__contact {
        display:none;
      }
    }

  }

  .form-group .control-label {
    font-size:1.25rem;
  }
}

@media (max-width: 500px) {
  .mainHeader {
    .mainNav {
      &__ctaBtn {
        font-size:1.2rem;
        padding:2%;
      }
    }
  }
  .mainSlider {

    &__slider {
      &__item {
        position: relative;
        height: 60vh;
        background: black;

        &__text {
          width:100%;
          margin: 0 5%;
          h2 {
            font-size:3.5rem;
          }
        }
      }
    }
  }
  .about h2, .secure h2 {font-size:3.5rem;}
}