.section {
  margin-top:10rem;
}
.subpage {
  .mainFooter {
    &:after {
      background-image:url('/images/footer-shape-right-white.svg');
    }
    &:before {
      background-image:url('/images/footer-shape-white.svg');
    }
  }
}

section.content {
  margin-bottom:4rem;
  a {
    color: $base-color;
    text-decoration:none;
    &:hover {
      color: darken($base-color,0);
    }
  }
  h1 {
    font-weight: 600;
    font-size: 4rem;
    margin-bottom: 4rem;
    padding-top:2rem;
    text-align:center;
  }
}
.mainHeader {
  position:absolute;
  top:0;
  left:0;
  right:0;
  z-index:99;
  padding: 2% 4%;
  transition: all .5s;
  &.minimize, &.subpage {
    background:black;
    position:fixed;
    max-height:74px;
    padding:5px 4%;
    .mainNav {align-items:center;max-height:74px;}
    .mainNav__menu, .mainNav__menu li {margin:0;}
    .mainNav__logo {
      margin-top:auto;
      svg {max-width:80px;width:80px;height:80px;}
    }
  }
  .mainNav {
    display:flex;
    justify-content: space-between;
    &__hamburger {
      z-index:10;
      display:none;
      border:0;
      margin:0;
      padding:0;
      span {
        height:3px;
        width:4rem;
        background:white;
        display:block;
        margin: 1rem 0;
        transition: all .25s;
      }
    }
    &.open {
      .mainNav {
        &__hamburger {
          span {
            &:nth-of-type(1) {
              transform:rotate(45deg);
              transform-origin: 0.3rem;
            }
            &:nth-of-type(2) {
              transform:translateX(100%);
              opacity:0;
            }
            &:nth-of-type(3) {
              transform:rotate(-45deg);
              transform-origin: 0rem;
            }
          }
        }
      }
    }
    &__menu {
      margin-top:1%;
      display:flex;
      transition: all .5s;
      justify-content: space-between;
      li {
        padding: 0 10px;
        list-style:none;
      }
      a {
        color: #ffffff;
        text-decoration:none;
        &:hover {
          color: darken($base-color,0);
        }
      }
    }
    &__logo {
      svg {width:185px;max-width:185px;transition: all .5s;}
    }

    &__ctaBtn {
      margin:0;
      text-transform:none;
      font-size:100%;
      font-weight:400;
      letter-spacing:inherit;
      display:flex;
      align-items:center;
      padding:15px;
      svg {
        fill: #ffffff;
        height: 18px;
        width:auto;
        margin-right:15px;
      }
    }
  }
}



.mainSlider {
  position:relative;
  background:black;
  .slick-dots {
    position:absolute;
    z-index:10;
    bottom:0;
    left:0;
    right:0;
    width:50%;
    display:flex;
    justify-content: flex-end;
    li {
      position:relative;
      list-style:none;
      cursor:pointer;
      button {
        margin:0;
        padding:0 2rem;
        padding-left:4rem;
        border:0;
        color:white;
        font-weight:600;
        font-size:1.4rem;
      }
      &:after {
        transition: all .5s;
        content: "";
        position:absolute;
        width:100px;
        left:0;
        top:0;
        background-repeat:no-repeat;
        height:100%;
        opacity:0.1;
        background-image:url('/images/slide-dot.svg');

        z-index:-1;
      }
      &:hover {
        &:after {
          opacity:0.3;
        }
      }
      &.slick-active {
        &:after {
          background-image:url('/images/slide-dot-active.svg');
          opacity:1;

        }
      }
    }
  }
  &__slider {

    &__item {
      position:relative;
      height:90vh;
      background:black;
      &__image {
        opacity:0.2;
        z-index:0;
        position:absolute;
        height:100%;
        width:100%;
        background-size:cover;
      }
      &__text {
        position: relative;
        z-index: 2;
        color: #fff;
        height: 90vh;
        width: 40%;
        margin-left: 20%;
        display: flex;
        align-items: center;
        h2 {
          font-size: 7rem;
          font-weight: 500;
          line-height: 8rem;
        }
      }
    }
  }

  &__aside {
    position:absolute;
    max-width:300px;
    width:20%;
    padding-left:30%;
    height:100%;
    z-index:10;
    top:1px;
    right:0;
    background-image:url(/images/slide-shape.svg);
    background-repeat:no-repeat;
    background-position:bottom right;
    background-size:100%;
    &__company {
      position:absolute;
      bottom: 5%;
      a {
        text-decoration:none;
        color: $light-gray;
        &:hover {
          color: $base-color;
        }
      }
    }

    &__contact {
      position:absolute;
      top:20%;
      color:white;
      .text--hightlight {color:$base-color;}
      a {
        color:white;text-decoration:none;
        &:hover {color: $base-color;}
      }
    }
  }

}


.contactForm {
  background:black;
  color:white;
  min-height:500px;
  position:relative;
  .proceeding {
    .contactForm__loader {display:flex;}
  }
  &__loader {
    display:none;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.7);
    z-index:2;
    align-items:center;
    justify-content: center;
  }
  a {
    text-decoration:none;
    color:white;
    &:hover {
      color: $base-color;
    }
  }
  button.button {
    padding: 0% 7%;
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 400;
  }
  form {
    margin: 10rem 0;
    position:relative;
  }
  &:before {
     left:0;
     top:0;
     content: "";
     position:absolute;
     height: 13vw;
     width:13vw;
     background-image:url('/images/form-shape.svg');
     background-size:cover;
   }

  &:after {
    right:0;
    top:0;
    content: "";
    position:absolute;
    height: 13vw;
    width:13vw;
    background-image:url('/images/form-shape-right.svg');
    background-size:cover;
  }
}

.slide-text-animation {
  transition: all 1s;
  opacity:1;
  transform:translateY(0px);
  &-out {
    opacity:0;
    transform:translateY(50px);
  }
}

.process {
  text-align:center;
  .step {
    text-transform:uppercase;
    font-size:2rem;
  }
  &__stepNo {
    font-size:9rem;
    line-height:9rem;
    font-weight:600;
    color: $base-color;
    display:block;
  }
  h3 {
    font-size:3rem;
    margin-top:1.5rem;
    margin-bottom:0;
    min-height:7rem;
  }
  img {
    height:105px;
    width:auto;
    margin: 4rem 0;
  }
  p {
    padding: 0 4em;
  }
}

.about {
  background: $base-color;
  padding: 10rem 0;
  color:white;

  h2 {
    font-weight:600;
    font-size:5rem;
  }

  &__ctaBtn {
    text-transform:none;
    font-size:100%;
    font-weight:400;
    letter-spacing:inherit;
    display:flex;
    align-items:center;
    padding:15px;
    color: $base-color;
    background:white;
    border-color:white;
    width:158px;
    &:hover, &:active, &:visited, &:focus {
      border-color:white;
      color: $base-color;
      opacity:0.8;
    }
    svg {
      fill: $base-color;
      height: 18px;
      width:auto;
      margin-right:15px;
    }
  }
}

.secure {
  text-align:center;
  h2 {
    font-weight:600;
    font-size:5rem;
    margin-bottom:8rem;

  }
  svg {
    width: 50px;
    height:auto;
  }
}

.contactForm {
  background:black;
  color:white;
  position:relative;
  text-align:center;
  padding-top:15rem;
  h2 {
    font-weight:600;
    font-size:4rem;
    margin-bottom:4rem;
  }
  &:before {
    left:0;
    top:0;
    content: "";
    position:absolute;
    height: 13vw;
    width:13vw;
    background-image:url('/images/form-shape.svg');
    background-size:cover;
  }

  &:after {
    right:0;
    top:0;
    content: "";
    position:absolute;
    height: 13vw;
    width:13vw;
    background-image:url('/images/form-shape-right.svg');
    background-size:cover;
  }
}


.mainFooter {
  background-color: $base-color;
  position:relative;
  color:white;
  padding-top:7rem;
  padding-bottom:2rem;
  a {
    color: white;
    text-decoration:none;
    &:hover {
      color: darken($base-color,10);
    }
  }
  svg {
    width: 130px;
  }
  &:before {
    left:0;
    top:0;
    content: "";
    position:absolute;
    height: 7vw;
    width:7vw;
    background-image:url('/images/footer-shape.svg');
    background-size:cover;
  }

  &:after {
    right:0;
    top:0;
    content: "";
    position:absolute;
    height: 7vw;
    width:7vw;
    background-image:url('/images/footer-shape-right.svg');
    background-size:cover;
  }
}

[data-animate] {
  transition: all 1s;
  &:nth-of-type(1) {
    transition-delay: 0s;
  }
  &:nth-of-type(2) {
    transition-delay: 0.25s;
  }
  &:nth-of-type(2) {
    transition-delay: 0.5s;
  }
  &:nth-of-type(3) {
    transition-delay: 0.75s;
  }
  &:nth-of-type(4) {
    transition-delay: 1s;
  }
  &:nth-of-type(5) {
    transition-delay: 1.25s;
  }
  opacity:0;
  transform:translateY(10%) scale(0.9);
  &.animated {
    opacity: 1;
    transform:translateY(0%) scale(1);
  }
}

.alert {
  padding: 1% 5%;
  fill:white;

  align-items: center;
  justify-content: center;
  transition: all .5s;
  margin-top:5%;
  display:none;
  svg {
    margin:0 5%;
  }
  &.show {
    display:flex;
  }

  &.success {
    background:#8ed3af;
  }

  &.error {
    background: $base-color;
  }

}

.svg-defs {display:none;}

.grecaptcha-badge {
  z-index:99999;
}
